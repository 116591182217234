<template lang="pug">
  include ../plugins/pug/btn

  div.dialog__window-content
    div.feedback__body
      h2.feedback__title.h2.fw-700 Get in touch
      form(@submit.prevent="handleSubmit").form
        label(
          :class="$v.form.name.$error && 'invalid'"
          for="name"
        ).form__label
          span Name
          input.input(
            type="text"
            name="name"
            v-model="form.name"
          )
          div
        label(
          :class="$v.form.linkedin.$error && 'invalid'"
          for="linkedin"
        ).form__label
          span LinkedIn
          input.input(
            type="text"
            name="linkedin"
            v-model="form.linkedin"
          )
        label(
          :class="$v.form.email.$error && 'invalid'"
          for="email"
        ).form__label
          span Email
          input.input(
            type="email"
            name="email"
            v-model="form.email"
          )
        label(
          :class="!isFileValid && 'invalid'"
          for="file"
        ).form__label.file__label
          span Your CV
          div.file__input
            div.file__upload
              img(
                :src="PlusIcon"
                alt="icon"
              )
            input.input(
              type="file"
              name="file"
              ref="fileInput"
              @change="fileSelected"
              accept="application/pdf"
            )
            div(
              v-if="isFileClosed"
              @click="fileClose"
            ).file__close
              img(
                :src="CloseIcon"
                alt="icon"
              )
            div.file__desc
              div.file__icons
                img(
                  :src="FileIcon"
                  alt="icon"
                )
              div.file__title {{ fileName }}
        label(for="message").form__label
          span Message
          textarea.input(
            name="message"
            v-model="form.message"
          )
        button(type="submit").btn.btn__send
          span Send
</template>

<script>
import { vacanciesForm } from '@/configs/validationRules'
// images
import PlusIcon from '@/assets/icons/plus.svg'
import CloseIcon from '@/assets/icons/close-icon.svg'
import FileIcon from '@/assets/icons/file.svg'
export default {
  name: 'VacanciesForm',
  data () {
    return {
      PlusIcon,
      CloseIcon,
      FileIcon,
      fileName: 'PDF (up to 10 MB in size).',
      selectedFile: false,
      isFileValid: true,
      isFileClosed: false,
      file: null,
      form: {
        name: '',
        email: '',
        linkedin: '',
        message: ''
      }
    }
  },
  validations () {
    return { form: vacanciesForm }
  },
  methods: {
    fileSelected (e) {
      this.isFileClosed = true
      this.selectedFile = e.target.files[0]
      if (this.selectedFile) {
        const maxSizeInBytes = 10 * 1024 * 1024 // 10 MB
        if (this.selectedFile.size > maxSizeInBytes) {
          this.isFileValid = false
          this.selectedFile = false
          this.file = null
          if (this.$refs.fileInput) this.$refs.fileInput.value = ''
        } else {
          this.isFileValid = true
          this.file = this.selectedFile
          this.fileName = e.target.files[0].name
        }
      }
    },
    fileClose () {
      this.isFileClosed = false
      this.isFileValid = true
      this.selectedFile = false
      this.file = null
      this.fileName = 'PDF (up to 10 MB in size).'
      if (this.$refs.fileInput) this.$refs.fileInput.value = ''
    },
    validation () {
      this.$v.form.$touch()
      return !this.$v.form.$invalid
    },
    validationFile () {
      this.isFileValid = !!this.selectedFile
      return this.isFileValid
    },
    async handleSubmit () {
      this.validation()
      this.validationFile()
      try {
        if (this.validation() && this.validationFile()) {
          const body = new FormData()
          body.append('file', this.file)
          const response = await this.$api.post(`${ process.env.VUE_APP_API }/files`,{ body })
          if (response.data.data.id) await this.$api.post(`${ process.env.VUE_APP_API }/items/mail`,{
            body: {
              ...this.form,
              file: response.data.data.id
            }
          })
          if (response?.code === 200 || response?.code === 204) {
            if (!this.$v.$invalid) {
              for (let key in this.form) {
                this.form[key] = ''
              }
            }
            this.$v.form.$reset()
            this.fileClose()
            this.$emit('updateIsShowPopup',false)
          }
        }
      } catch (error) {
        console.error('Error', error)
      }
    }
  }
}
</script>
